import * as React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FilePath } from '../../api/api-constants'
import { getAuthorizationHeaders } from '../../api/auth-api'
import { getApi } from '../../utils/api/Api'
import ParserParameter from '../ParserParameter/ParserParameter'
import { OnSuccessAction } from '../ParserParameter/OnSuccessAction'
import { OnCreateBorder } from './onCreateBorder';
import { navigate } from '@reach/router'
import { tkheadersUploadFile } from '../../api/tkheaders-pdf-to-image'
import { bindActionCreators } from 'redux'
const { Dialog, Button, Icon, TextInputField } = require('evergreen-ui')
const axios = require('axios')

interface IProps {
  fileId: string
  fileName: string
}

interface IState {
  isDrawBorderDialogOpen: boolean
  pageNumber: string
  isLoading: boolean,
  error: string,
}

class DrawBorder extends React.Component<any, any> {
  public static defaultProps = {
    buttonText: 'Draw Border',
    buttonTheme: 'default',
  }
  public state = {
    isDialogOpen: false,
    pageNumber: null,
    isLoading: false,
    error: '',
  }

  private showDialog = () => this.setState({ isDialogOpen: true })
  private closeDialog = () => this.setState({ isDialogOpen: false, error:'', pageNumber:'' })

  public render(): React.ReactNode {
    const { pageNumber } = this.state
    const { param } = this.props
    return (
        <>
          <Dialog
            contentContainerProps={{
              padding: 10,
            }}
            isShown={this.state.isDialogOpen}
            onCloseComplete={this.closeDialog}
            confirmLabel={'Draw Border'}
            hasFooter={false}
            hasHeader={true}
            title="Draw Border"
            topOffset={'6vmin'}
            preventBodyScrolling={true}
          >
            <Container>
              <div
                style={{
                  padding: '5px 10px',
                }}
              >
                <TextInputField
                  onChange={this.handlePageNumberChange}
                  value={pageNumber}
                  spellCheck={false}
                  label={'Page'}
                  placeholder={'Page number'}
                />
              </div>
            </Container>
            {this.state.error && <StyledAlert>{this.state.error}</StyledAlert>}
            <ParserParameter
                  paramsParameter={param}
                />
            <Button
              style={{
                margin: '5px 0',
                float: 'right',
              }}
              isLoading={this.state.isLoading}
              appearance="primary"
              type={'submit'}
              onClick={this.onSubmit}
            >
              Draw Border
            </Button>
            <Button
              appearance="minimal"
              onClick={this.closeDialog}
              style={{
                margin: '5px 0',
                float: 'right',
              }}
            >
              Cancel
            </Button>
          </Dialog>
          <Button className="icon" onClick={this.showDialog}>
            {this.displayParser()}
          </Button>
        </>
      )
  }

  private onSubmit = async () => {
    const { pageNumber } = this.state
    this.setState({ isLoading: true, error: '' })
    this.downloadFile(this.props.fileId, this.props.fileName)
      .then(async (input_pdf: any) => {
        const selectedPage = pageNumber ? (+pageNumber) : 1;
        const parserParams: any = {}
       const params = this.props.params
      for (var i = 0; i < params.length; i++) {
        if (params[i].type === 'str') {
            parserParams[params[i].key] = params[i].value.trim()
        } else if (params[i].type === 'int' || params[i].type === 'float') {
          const temp = +params[i].value
          parserParams[params[i].key] = temp
        } else {
            parserParams[params[i].key] = params[i].value
        }
      }
        tkheadersUploadFile(pageNumber, input_pdf, parserParams)
        .then((result: any) => {
          if (result.length > 0) {
            this.props.OnSuccessAction('')
            this.props.OnCreateBorder(result)
            var borderData = this.props.borderData
            if (borderData && borderData.page) {
              var pages = borderData.page;
              if (selectedPage > 1) {
                borderData.page = [selectedPage]
              }
            }
            this.setState({ isLoading: false, error: '' })
            this.closeDialog()
            navigate(`/newtableconfig/`, {
              state: {
                height: result[0]['height'],
                image: result[0]['image'],
                width: result[0]['width'],
                fileName: [input_pdf],
                page: selectedPage
              },
            })
          } else {
            this.setState({ error: 'Invalid page number', isLoading: false })
          }
        })
        .catch((e: any) => {
          let error = e.response && e.response.data ? e.response.data.error: 'Unknown Error'
          if (error == "A valid `page` parameter missing in the request body")
            error= 'Invalid page number'
          this.setState({ error: error, isLoading: false })

        })
      })
      .catch((e: any) => {
        const error = e.response && e.response.data ? e.response.data.error: 'Unknown Error'
        this.setState({ error: error, isLoading: false })
      })
    }


  private handlePageNumberChange = (e: any) => {
    this.setState({ pageNumber: e.target.value.trim() })
  }

  private displayParser() {
    const { buttonText } = this.props
    if (this.props.page === 'home') {
      return <Icon icon={'refresh'} color={'muted'} size={16} />
    } else {
      return <span> {buttonText} </span>
    }
  }

  private downloadFile = async (fileId: string, fileName: string) => {
    var bodyFormData = new FormData()
    var api_url = null

    bodyFormData.set('file_pk', fileId)
    bodyFormData.set('response_file', 'input_pdf')
    api_url = getApi(FilePath.downloadFile)

    return axios({
      method: 'post',
      url: api_url,
      headers: {
        'Content-Type': 'multipart/form-data',
        ...await getAuthorizationHeaders()
      },
      data: bodyFormData,
      responseType: 'blob',
    })
      .then((res: any) => {
       
        const blob = new Blob([res.data], { type: res.headers['content-type'] })
        var file = new File([blob], fileName,{ type: blob.type });
        return file;
      })
      .catch((e: any) => {
        const error = e.response && e.response.data ? e.response.data.error : 'Unable to download file'
        this.setState({ error: error, isLoading: false })
      })
  }
}



const Container = styled.span`
  .errorMessage{
    color: red;
    font-size: 10px
    position: relative
    top: -20px;
  }
`
const StyledAlert = styled.div`
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  margin: 20px 0;
  padding: 10px;
`

const mapStateToProps = (storeData: any) => {
    return {
      params: storeData.onChangeReducer.params,
      borderData: storeData.createNewBorder
    }
  }
  
  const mapDispatch = (dispatch: any) => {
    return bindActionCreators(
      {
        OnSuccessAction: OnSuccessAction,
        OnCreateBorder: OnCreateBorder
      },
      dispatch)
  }
  
  export default connect(mapStateToProps, mapDispatch)(DrawBorder)
