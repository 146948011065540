import dayjs from 'dayjs'
import * as React from 'react'
import styled from 'styled-components'
import { downloadFile } from '../../api/file-downloader-api'
import { GetFileDetail_files } from '../../schemaTypes'
import {cleanInputFile} from '../../utils/FileUtils'
import { extractFileDataFromS3Key } from '../../utils/s3'
import BankStatusComp from '../BankStatus/BankStatus'
import CustomCard from '../CustomCard/CustomCard'
import FileRange from '../FileRange'
import FileStatus from '../FileStatus/FileStatus'
import FileStatusStripe from '../FileStatusStripe/FileStatusStripe'
import ParentUserId from '../parentUserId'
import RerunConversion from '../RerunConversion/RerunConversion'
import RerunParser from '../RerunParser/RerunParser'
import DrawBorder from '../NewConfig/CreateNewBorderPopup'
import ReplaceParsedOutput from '../ReplaceParsedOutput/ReplaceParsedOutput'
import StatementDate from '../statementDate/statementDate'
import Subtype from '../Subtype'
import TaskId from '../TaskId'
const { Badge } = require('evergreen-ui')

const { Heading, Button, Text } = require('evergreen-ui')

enum fileDownloadApiStatusEnum {
  none,
  downloadingPdfToDisplay,
  downloadingPdf,
  downloadingExcel,
  downloadCanopy,
  downloaded,
  failed,
}

interface IState {
  fileDownloadApiStatus: fileDownloadApiStatusEnum
  fileDownloadErrorMessage: string
}

const initialState = {
  fileDownloadApiStatus: fileDownloadApiStatusEnum.none,
  fileDownloadErrorMessage: '',
}

interface IProps extends GetFileDetail_files {
  style?: React.CSSProperties
}

class FileDetailOverviewCard extends React.Component<any, any> {
  public state = initialState

  public render() {
    const {
      inputFile,
      parsingStatus,
      bankStatus,
      createdAt,
      updatedAt,
      fileUuid,
      params,
      style,
      subtype,
      bankCode,
      pageRange,
      taskId,
      parentUserId,
      statementDate,
      bankPredictedBy,
      originalFile,
    } = this.props
    const fileName = inputFile !== null ? cleanInputFile(inputFile) : originalFile

    return (
      <CustomCard
        type={'allFiles'}
        style={{
          minHeight: 200,
          position: 'relative',
          ...style,
        }}
      >
        <Container>
          <FileStatusStripe status={parsingStatus} />
          <span className="name-container">Parsing status:</span>
          <FileStatus status={parsingStatus} />
          <span style={{ marginRight: 12 }} />
          <span className="name-container"> Bank status:</span>
          <BankStatusComp status={bankStatus} />
          <span style={{ marginRight: 12 }} />
          <Subtype subtype={subtype} />
          <FileRange pageRange={pageRange} />
          <TaskId taskId={taskId} />
          <ParentUserId parentUserId={parentUserId} />
          <StatementDate statementDate={statementDate} />
          <Badge style={{ textTransform: 'capitalize', marginLeft: '10px' }}>
            <span style={{ fontWeight: 400 }}>Model: </span>
            {bankPredictedBy ? (
              <span>{bankPredictedBy}</span>
            ) : (
              <span style={{ textTransform: 'lowercase' }}>n/a</span>
            )}
          </Badge>
          <Heading is={'h1'}><a href='#' onClick={this.showPdf}>{fileName}</a></Heading>
          <section className={'timestamps'}>
            <Text color={'muted'}>
              <b>Created on{' : '}</b>
              {dayjs(createdAt).format('DD MMM YYYY, HH:mm')}
              {/* {new Date(createdAt).toUTCString()} */}
              <b> Last updated on{' : '}</b>
              {dayjs(updatedAt).format('DD MMM YYYY, HH:mm')}
              {/* {new Date(updatedAt).toUTCString()} */}
            </Text>
          </section>
          <section className={'button-container'}>
            <Button
              isLoading={
                this.state.fileDownloadApiStatus === fileDownloadApiStatusEnum.downloadingPdf
              }
              onClick={this.downloadPdf}
            >
              Download PDF
            </Button>
            <Button
              isLoading={
                this.state.fileDownloadApiStatus === fileDownloadApiStatusEnum.downloadingExcel
              }
              onClick={this.downloadExcel}
            >
              Download Excel
            </Button>
            <Button
              isLoading={
                this.state.fileDownloadApiStatus === fileDownloadApiStatusEnum.downloadCanopy
              }
              onClick={this.downloadCanopyUl}
            >
              Download Canopy UL
            </Button>

            <RerunConversion
              fileId={fileUuid}
              bankName={bankCode}
              subType={subtype}
              param={params}
              pageRange={pageRange}
            />

            <RerunParser
              //@ts-ignore
              fileId={fileUuid}
              fileStatus={parsingStatus}
              param={params}
              page="detail"
              bankName={bankCode}
              subType={subtype}
              pageRange={pageRange}
            />
            <ReplaceParsedOutput
              fileId={fileUuid}
              fileStatus={parsingStatus}
            />
            <DrawBorder 
              fileId={fileUuid}
              fileName={fileName}
            />
          </section>
        </Container>
      </CustomCard>
    )
  }

  private downloadCanopyUl = async (e: any) => {
    e.preventDefault()
    this.updateFileDownloadState(fileDownloadApiStatusEnum.downloadCanopy)
    await downloadFile(this.props.fileUuid, this.getFilename('xlsx'), 'canopy_ul')
    this.updateFileDownloadState(fileDownloadApiStatusEnum.downloaded)
  }

  private downloadExcel = async (e: any) => {
    e.preventDefault()
    this.updateFileDownloadState(fileDownloadApiStatusEnum.downloadingExcel)
    await downloadFile(this.props.fileUuid, this.getFilename('xlsx'), 'output_excel')
    this.updateFileDownloadState(fileDownloadApiStatusEnum.downloaded)
  }

  private downloadPdf = async (e: any) => {
    e.preventDefault()
    this.updateFileDownloadState(fileDownloadApiStatusEnum.downloadingPdf)
    await downloadFile(this.props.fileUuid, this.getFilename('pdf'), 'input_pdf')
    this.updateFileDownloadState(fileDownloadApiStatusEnum.downloaded)
  }

  private showPdf = async (e: any) => {
    e.preventDefault()
    this.updateFileDownloadState(fileDownloadApiStatusEnum.downloadingPdfToDisplay)
    await downloadFile(this.props.fileUuid, this.getFilename('pdf'), 'input_pdf', true)
    this.updateFileDownloadState(fileDownloadApiStatusEnum.downloaded)
  }

  private getFilename = (type: 'xlsx' | 'pdf') =>
    `${extractFileDataFromS3Key(this.props.inputFile).filename}.${type}`

  private updateFileDownloadState = (status: fileDownloadApiStatusEnum) => {
    this.setState({ fileDownloadApiStatus: status })
  }
}

const Container = styled.div`
  height: 100%;
  .button-container {
    display: flex;
    > button {
      margin: 20px 20px 0 0;
    }
  }
  h1 {
    margin: 12px 0;
  }
  .name-container {
    font-size: 12px;
    padding-right: 5px;
  }
`

export default FileDetailOverviewCard
